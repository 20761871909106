import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const AboutPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>About — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={'About page of Anubhav. ' + site.siteMetadata.description}
        />
      </Helmet>
      <Container className="section about">
        <div
          className="banner"
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1633988354540-d3f4e97c67b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1629&q=80)`,
          }}
        />
        <Row className="header">
          <Col sm={12} md="auto">
            <Image roundedCircle src="/assets/portrait.jpg"></Image>
          </Col>
          <Col sm={12} md="auto" className="text">
            <h2 style={{ paddingBottom: '.25rem' }}>
              {site.siteMetadata.home.name}
            </h2>
            <p>{site.siteMetadata.home.role}</p>
          </Col>
          <p className="content" style={{ paddingBottom: '1.5rem' }}>
            Hi! Thanks for taking out the time to check my portfolio! I am
            currently in my junior year pursuing my degree in Information
            Technology from{' '}
            <a
              href="https://jaipur.manipal.edu/"
              target="_blank"
              className="aboutAnchorLink"
            >
              Manipal University
            </a>{' '}
            Jaipur, India. I'm an individual who enjoys learning while exploring
            as much as possible, whether it's about emerging technologies, the
            professional world, or even design. I love to see practicality and
            action in everything around me and I love the freedom to create and
            innovate with my own ideas and contributions which I can shape with
            my flair for perfection. Hence, I am passionate about startups since
            it allows to expand our creativity and build things from scratch. I
            believe a startup gives an opportunity to grow, absorb and learn in
            an unrivalled engaging and fast-paced environment.
            <br />
            <br />I am a highly motivated guy who’s always inquisitive to learn
            new things. I firmly believe in a learning-by-doing philosophy. I’ve
            majorly worked in flutter and react and prefer nodejs for backend. I
            love the innovation rush of hackathons hence I keep participating in
            them and try to create projects which aims to solve real-world
            problems thus adding value to my skillset. While designing, be it
            graphics or UI - my goal is to make unique, intuitive, and
            high-quality professional designs by putting clients’ ideas together
            that conveys their brand’s vision effectively. I’m a keen observer
            constantly seeking to outgrow my technical perceptions. I believe,
            there is no such thing as a "best" version of oneself. A learning
            curve should always exist. I can always do better.
            <br />
            <br />I take my work very seriously but also take out time for other
            creative and recreational activities. I like to indulge in musical
            tastes time to time and try to play the ukulele too. Apart from
            that, I love to binge-watch thriller TV shows and read books in my
            leisure time. I think of myself as a social person and if you are
            not one to take my pathetic jokes too seriously, then we can be good
            friends. In fact why don't you shoot me a message and we can get in
            touch!
          </p>
          <br />
          <h2 className="aboutSubHeading">
            My First Internship as a Software Developer
          </h2>
          <p className="content">
            In the summer of 2021, I completed my first internship as a software
            developer (flutter) in a stealth startup which goes by the name of{' '}
            <a
              href="https://reverylab.com/"
              target="_blank"
              className="aboutAnchorLink"
            >
              Revery
            </a>{' '}
            whose mission is to make wellness accessible and affordable through
            the medium of gaming. The learning curve was quite exponential in
            those 9 weeks as the startup was in its early stage so I even got
            the option to brainstorm about new ideas and what all other features
            could we include in the application.
          </p>

          <p className="content">
            I worked mostly on the frontend using flutter and learnt a lot about{' '}
            <a
              href="https://firebase.google.com/"
              target="_blank"
              className="aboutAnchorLink"
            >
              firebase
            </a>{' '}
            as it was being used in the backend. I integrated a lot of{' '}
            <a
              href="https://rive.app/"
              target="_blank"
              className="aboutAnchorLink"
            >
              {' '}
              rive
            </a>{' '}
            animations and how to control those from within the app. I got
            insights about various Software design patterns and principles such
            as what exactly is modular code and concepts like separation of
            concerns. It was highly encouraged to write well designed, testable,
            scalable and Clean code. I even documented the code with comments
            where ever I felt it was necessary. Took part in the development
            lifecycle and collaborated with cross functional teams which
            included product managers, UI/UX designers, QA engineers in a fast
            paced environment. I got a hang of dart’s null safety which was one
            of the major highlights of my tenure. Bugs have always been an
            integral part of a software developer’s life and I proudly say that
            I did a lot of troubleshooting, caught and fix numerous bugs.
            Refactored my code every now and then, participated in code reviews
            and app testing as well. Somedays, I used to found myself in between
            major merge conflicts rather merge wars which I fought courageously
            and resolved all. Daily standups, guild halls and team discussions
            were my favorite. The most interesting fact about my internship was
            I used to work at night according to IST so that I could collaborate
            well with my team working in the US.
          </p>
          <p className="content">
            Time flew away. It was my last day. I bid{' '}
            <a
              href="https://www.linkedin.com/posts/reverylab_internship-activity-6827043725441687552-5zxs/"
              target="_blank"
              className="aboutAnchorLink"
            >
              {' '}
              adieu
            </a>{' '}
            to the entire team. Thanks{' '}
            <a
              href="https://www.linkedin.com/in/tammie-siew/"
              target="_blank"
              className="aboutAnchorLink"
            >
              Tammie
            </a>{' '}
            and{' '}
            <a
              href="https://www.linkedin.com/in/khoarevery"
              target="_blank"
              className="aboutAnchorLink"
            >
              Khoa
            </a>{' '}
            for believing in me and giving me this chance to grow not only as an
            engineer but even as an individual. Khoa was and is the best CTO I
            have ever come across and not to forget{' '}
            <a
              href="https://www.linkedin.com/in/stephanieally/"
              target="_blank"
              className="aboutAnchorLink"
            >
              Steph
            </a>{' '}
            my engineering manager who always supported and motivated me to give
            my best and answered all the doubts and queries with utmost
            patience. When I was exiting, TechCrunch published an{' '}
            <a
              href="https://techcrunch.com/2021/08/03/revery-gets-2m-to-improve-mental-health-with-mobile-gaming-techniques/"
              target="_blank"
              className="aboutAnchorLink"
            >
              article
            </a>{' '}
            with a team picture in it to announce that Revery received $2M in
            funding. It was really a proud moment for me as I had the
            opportunity to contribute and add value to their product.
          </p>
        </Row>
        {/* <Row>
          <Col md={9}>
            <h2></h2>
            <p></p>
          </Col>
          <Col md={3}></Col>
        </Row> */}
      </Container>
    </Layout>
  );
};
export default AboutPage;
export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
        home {
          name
          role
          location
        }
      }
    }
  }
`;
